"use client";
import { useEffect } from "react";

const useStoreUtmTags = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmTags = {
      utm_source: urlParams.get("utm_source") || "",
      utm_medium: urlParams.get("utm_medium") || "",
      utm_campaign: urlParams.get("utm_campaign") || "",
      utm_term: urlParams.get("utm_term") || "",
      utm_content: urlParams.get("utm_content") || "",
      utm_referrer: urlParams.get("utm_referrer") || "",
      referrer: urlParams.get("referrer") || "",
      gclientid: urlParams.get("gclientid") || "",
      gclid: urlParams.get("gclid") || "",
      fbclid: urlParams.get("fbclid") || "",
    };
    localStorage.setItem("utmTags", JSON.stringify(utmTags));
  }, []);
};

export default useStoreUtmTags;
